import useUserRoles from "@/uses/useUserRoles";
import {
  AssessmentItem,
  AssessmentSearchItem,
  SubjectProfile,
} from "@/api/interfaces/SubjectsInterfaces";
import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue";

import CalendarTodayIcon from "@/assets/icons/cards/CalendarToday.vue";
import PhoneIcon from "@/assets/icons/cards/Phone.vue";
import UserIcon from "@/assets/icons/cards/User.vue";
import WorkOutlineIcon from "@/assets/icons/cards/WorkOutline.vue";
import CorporateFareIcon from "@/assets/icons/cards/CorporateFare.vue";
import SexMaleIcon from "@/assets/icons/SexMale.vue";
import CommentIcon from "@/assets/icons/cards/Comment.vue";

import EditIcon from "@/assets/icons/Edit.vue";
import DeleteIcon from "@/assets/icons/Delete.vue";

import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { MetadataMutations } from "@/store/metadataModule";
import useSexFormatter from "@/uses/useSexFormatter";
import { getAssessmentsSearch, viewSubjectProfile } from "@/api/SubjectsApi";
import { SortingDirection, SortingItem } from "@/interfaces/SortingItem";
import { showError, showErrorOptional } from "@/utils/notificationUtils";
import TableHeaderComponent from "@/components/TableHeaderComponent/TableHeaderComponent.vue";
import useDateFormatter from "@/uses/useDateFormatter";

export default defineComponent({
  name: "ViewSubject",
  props: { subject: Object as () => SubjectProfile },
  components: {
    CalendarTodayIcon,
    PhoneIcon,
    UserIcon,
    WorkOutlineIcon,
    CorporateFareIcon,
    SexMaleIcon,
    CommentIcon,
    EditIcon,
    DeleteIcon,
    TableHeaderComponent,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const subjectId = route.params.id as string;
    const siteId = route.params.siteId as string;

    const profile = ref<SubjectProfile>();

    const updateProfile = () => {
      viewSubjectProfile(subjectId, siteId).then((subjectProfile) => {
        profile.value = {
          ...subjectProfile,
        };

        store.commit(
          MetadataMutations.SET_BREAD_CRUMBS_NAME,
          `${profile.value.subject_id}`
        );
      });
    };

    const fieldNames = {
      assessment_id: "assessment_id",
      assessment_time: "assessment_timestamp",
      score_mmse: "score_mmse",
      score_adascog: "score_adascog",
      status: "status",
    };

    const list = ref<{ data: AssessmentSearchItem[] }>({
      data: [],
    });

    const state = reactive({
      paging: {
        page: 1,
        pageSize: 5,
        total: 0,
      },
      sorting: null as SortingItem[] | null,
      isLoading: false,
      dialogVisible: false,
    });

    const updateAssessments = async () => {
      state.isLoading = true;
      try {
        const assessments = await getAssessmentsSearch(
          {
            paging: {
              page_number: state.paging.page,
              amount_per_page: state.paging.pageSize,
            },
            sorting: state.sorting ? state.sorting : undefined,
          },
          subjectId,
          siteId
        );
        state.paging.total = assessments.total;
        list.value.data = assessments.entities;
      } catch (ex) {
        showErrorOptional("Error while updating assessments", ex);
        console.log(ex);
      } finally {
        state.isLoading = false;
      }
    };

    onBeforeMount(() => {
      updateProfile();
      updateAssessments();
    });

    return {
      profile,
      ...useUserRoles(store),
      ...useSexFormatter(),
      ...useDateFormatter(),
      deleteSubject() {
        router.push(`/subjects/`);
      },
      editSubject() {
        router.push(
          `/subjects/edit-subject/${
            profile.value?.site_id ? `${profile.value.site_id}/` : ""
          }${profile.value?.id}`
        );
      },

      state,
      list,
      fieldNames,
      siteId,
      getSortingValueForField: computed(() => (field: string) => {
        if (state.sorting) {
          const sorting = state.sorting[0];
          return sorting.field === field ? sorting.direction : null;
        }
        return null;
      }),
      pageChange: (page: number) => {
        state.paging.page = page;
        updateAssessments();
      },
      sortingChange(field: string, direction: SortingDirection | null) {
        if (direction === null) {
          state.sorting = null;
        } else {
          state.sorting = [{ field, direction }];
        }
        updateAssessments();
      },
    };
  },
});
