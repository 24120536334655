<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="9tijhxc3ta"
        d="M13.327 1.333C13.327.6 12.733 0 12 0H1.333C.6 0 0 .6 0 1.333v8c0 .734.6 1.334 1.333 1.334h9.334l2.666 2.666-.006-12zm-1.327 0v8.78l-.78-.78H1.333v-8H12zM2.667 6.667h8V8h-8V6.667zm0-2h8V6h-8V4.667zm0-2h8V4h-8V2.667z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0L16 0 16 16 0 16z"
                transform="translate(-137 -415) translate(105 168) translate(32 240) translate(0 7)"
              />
              <g
                transform="translate(-137 -415) translate(105 168) translate(32 240) translate(0 7) translate(1.333 1.333)"
              >
                <mask id="s329qvdxob" fill="#fff">
                  <use xlink:href="#9tijhxc3ta" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#9tijhxc3ta" />
                <g fill="#90A4AE" mask="url(#s329qvdxob)">
                  <path
                    d="M0 0H16V16H0z"
                    transform="translate(-1.333 -1.333)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
