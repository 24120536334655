<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="lr2amw1vya"
        d="M6.333 7.333c1.287 0 2.334 1.047 2.334 2.334C8.667 10.953 7.62 12 6.333 12 5.047 12 4 10.953 4 9.667 4 8.38 5.047 7.333 6.333 7.333zm0-1.333C4.307 6 2.667 7.64 2.667 9.667c0 2.026 1.64 3.666 3.666 3.666 2.027 0 3.667-1.64 3.667-3.666 0-.774-.24-1.487-.647-2.08L12 4.947v1.72h1.333v-4h-4V4h1.72L8.407 6.647C7.82 6.24 7.107 6 6.333 6z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g
              transform="translate(-137 -317) translate(105 168) translate(32 142) translate(0 7)"
            >
              <path d="M0 0H16V16H0z" />
              <mask id="ledn3zzktb" fill="#fff">
                <use xlink:href="#lr2amw1vya" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#lr2amw1vya" />
              <g fill="#90A4AE" mask="url(#ledn3zzktb)">
                <path d="M0 0H16V16H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
